<template>
  <!-- 三角箭头 -->
  <div class="box" :style="getBack()">

    <div class="item" ref="sliders" v-for="(item,index) in DataArr " @click="updateValue($event,index)" :style="getItemStyle(index)">
      <i class="el-icon-caret-bottom"></i>
      <!-- <div class="area" :style="{'width':width3}">
        <div class="show" :style="{'width':width3}">

          <i class="el-icon-caret-bottom"></i>
        </div>
      </div> -->
    </div>
  </div>

</template>

<script >
import { getAngle2 } from '@/utils/index'

export default {
  name: 'paryt',
  props: ['DataArr', 'width', 'height', 'width1', 'height1', 'colorList', 'width3', 'borderColor'],
  data() {
    return {
      touchStartX: 0,
      touchStartY: 0,
      touchStartAngle: 0,
      flag: false,
      centerPointerLeft: 0,
      centerPointerTop: 0,
      degRota: 0,
      radius: 0,
      baseColor: '#fff',
      refBox: {},
      rota: 0
    }
  },

  methods: {
    updateValue(event, index) {
      let min = 0,
        max = 360
      let num = index * (360 / 21)
      const centerX = this.$refs.sliders[index].offsetWidth / 2
      const centerY = this.$refs.sliders[index].offsetHeight / 2
      // 获取容器中心点
      // const centerX = this.$refs.sliders.offsetWidth / 2
      // const centerY = this.$refs.sliders.offsetHeight / 2
      // console.log(centerX, centerY);
      // 鼠标位置到中心x和y方向的距离
      const deltaX = event.offsetX - centerX
      const deltaY = event.offsetY - centerY

      let angle = Math.atan2(deltaY, deltaX) + Math.PI / 2
      // 转换为角度 0-360
      let newValue = ((angle * 180) / Math.PI + 360) % 360
      // 将360分段
      newValue = Math.round(newValue / 3.6) * 3.6
      // 根据百分比计算滑块的值
      newValue = (newValue / 360) * (max - min) + min

      if (newValue <= min) {
        newValue = max
      } else if (newValue > max) {
        newValue = max
      }
      let value = newValue.toFixed(0)
      // console.log(value)
      let num3 = 360 / this.DataArr.length / 2
      let num2 = Math.floor((Number(value) + num + num3) % 360)

      this.$emit('rota', { rota: num2 })
    },
    getBack() {
      // let str = `1px solid ${borderColor}`
      return {
        width: this.width,
        height: this.height
        // border: str
      }
    },
    getItemStyle(index) {
      let num = 360 / this.DataArr.length / 2
      return {
        transform: `rotate(${index * (360 / this.DataArr.length) + num}deg)`,
        width: this.width1,
        height: this.height1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
  border-radius: 50%;
  transition: 1s;
  // border: 1px solid #000;
  overflow: hidden;
}
.item {
  position: absolute;
  display: flex;
  justify-content: center; // 主轴居中
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  // background-color: pink;
  .area {
    position: absolute;
    z-index: 99;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20%;
    .show {
      color: #000;
      // 字体的旋转
      word-wrap: break-word;
      font-size: 10px;
    }
  }
}
.el-icon-caret-bottom {
  color: red;
  font-size: 20px;
}
</style>
