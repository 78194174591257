export const colorList= [
  {
    color: "#af2023",
    num: 1,
  },
  {
    color: "#e62129",
    num: 1,
  },
  {
    color: "#ded572",
    num: 1,
  },
  {
    color: "#c4a13f",
    num: 1,
  },
  {
    color: "#fffcdb",
    num: 1,
  },
  {
    color: "#757475",
    num: 1,
  },
  {
    color: "#811c1d",
    num: 1,
  },
]

export const  colorList2= [
  {
    color: "#e62129",
    num: 2,
  },
  {
    color: '#c4a13f',
    num: 4,
  },
  {
    color: "#af2023",
    num: 3,
  },
  {
    color: "#d1cc94",
    num: 3,
  },
  {
    color: "#811c1d",
    num: 1,
  },
  {
    color: "#757475",
    num: 3,
  },
  {
    color: "#af2023",
    num: 5,
  },
]



export const handlerData = (DataArr,colorList) => {
  let list = []
  let halfNum= 0
  // 动态计算背景
  let numcount = 360 / DataArr.length
  halfNum = numcount / 2
  let arr = []
  let arr2 = []
  for (let index = 0; index <= 22; index++) {
    let nuu = Math.floor(numcount * index) // 每个单元格的 deg
    if (nuu <= 360) {
      arr.push(nuu)
    }
  }
  arr.forEach((item, index) => {
    if (item > 0) {
      let num = item - 1
      arr2.push(num)
    }
  })
  let arr3 = [...arr, ...arr2].sort((a, b) => a - b)
  let cuurent = 0
  let arr4 = []
 colorList.forEach((item) => {
    let a = item.num * 2
    for (let index = 0; index < a; index++) {
      arr4.push(item.color)
    }
  })
  arr3.forEach((item, i) => {
    if (i % 2 == 0) {
      list.push(`${arr4[cuurent]} ${item}deg`)
      list.push(`${arr4[cuurent]} ${arr3[i + 1]}deg`)
    } else {
      if (item) {
        list.push(`#fff ${item}deg`)
        if (arr3[i + 1]) {
          list.push(`#fff ${arr3[i + 1]}deg`)
        }
      }
    }
    if (cuurent <= arr4.length) {
      cuurent++
    }
  })
  list.splice(-2, 2)
  return {
    list,
    halfNum

  }
}