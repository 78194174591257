<template>
  <!-- <slogan :listData="data4" :colorList="colorList2" :DataArr="sixData" width="100vh" height="100vh" width1="100vh" height1="100vh" width3="105vh"></slogan> -->
  <div class="box" ref="box" :style="getBack()">
    <div class="item" style="transform:rotate(17deg)">
      <div class="area" :style="{'width':width3}">
        <div class="show" :style="{'width':width3}">
          女工工作
        </div>
      </div>
    </div>
    <div class="item" style="transform:rotate(68deg)">
      <div class="area" :style="{'width':width3}">
        <div class="show" :style="{'width':width3}">
          工会工作
        </div>
      </div>
    </div>
    <div class="item" style="transform:rotate(129deg)">
      <div class="area" :style="{'width':width3}">
        <div class="show" :style="{'width':width3}">
          党支部工作
        </div>
      </div>
    </div>
    <div class="item" style="transform:rotate(180deg)">
      <div class="area" :style="{'width':width3}">
        <div class="show" :style="{'width':width3}">
          青年工作
        </div>
      </div>
    </div>
    <div class="item" style="transform:rotate(215deg)">
      <div class="area" :style="{'width':width3}">
        <div class="show" :style="{'width':width3}">
          统战工作
        </div>
      </div>
    </div>

    <div class="item" style="transform:rotate(249deg)">
      <div class="area" :style="{'width':width3}">
        <div class="show" :style="{'width':width3}">
          纪检监察
        </div>
      </div>
    </div>
    <div class="item" style="transform:rotate(317deg)">
      <div class="area" :style="{'width':width3}">
        <div class="show" :style="{'width':width3}">
          宣传思想文化

        </div>
      </div>
    </div>
  </div>

</template>

<script >
import _ from 'lodash'
import { getAngle2, throttle } from '@/utils/index'

export default {
  name: 'paryt',
  props: ['DataArr', 'width', 'height', 'width1', 'height1', 'colorList', 'width3', 'listData'],
  data() {
    return {
      touchStartX: 0,
      touchStartY: 0,
      touchStartAngle: 0,
      flag: false,
      centerPointerLeft: 0,
      centerPointerTop: 0,
      degRota: 0,
      radius: 0,
      baseColor: '#fff',
      // list: [],
      // halfNum: 0,
      starRota: 0,
      endRota: 0,
      refBox: {},
      rota: 0,
      isMouseDown: false,
      difference: 0,
      flag1: false
    }
  },

  watch: {
    DataArr: {
      handler(value) {
        // console.log(1111111)
        // this.handlerData()
      },
      immediate: true,
      deep: true
    }
    // handlerData
  },
  created() {
    // console.log(this.listData)
    // this.handlerData()
  },
  methods: {
    getBack() {
      let str = `conic-gradient(from ${this.listData.halfNum}deg,`
      this.listData.list.forEach((item, index) => {
        if (index == this.listData.list.length - 1) {
          str += `${item}`
        } else {
          str += `${item},`
        }
      })
      str += `)`
      return {
        // backgroundImage: str,
        width: this.width,
        height: this.height,
        transform: `rotate(${this.degRota}deg)`
      }
    },
    getItemStyle(index) {
      return {
        // transform: `rotate(${index * (360 / this.DataArr.length)}deg)`,
        width: this.width1,
        height: this.height1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
  border-radius: 50%;
  transition: 1s;
  // border: 0.5vh solid #fff;
  overflow: hidden;
}
.item {
  position: absolute;
  display: flex;
  justify-content: center; // 主轴居中
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 100vh;
  height: 100vh;

  .area {
    position: absolute;
    z-index: 99;
    // padding: 10%;
    box-sizing: border-box;

    // width: 4vh;
    // height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20%;
    .show {
      color: #000;
      // 字体的旋转
      word-wrap: break-word;
      font-size: 20px;
      font-weight: 700;

      // width: 1.25rem;
    }
  }
}
</style>
