<template>
  <div class="clock">
    <div class="table" v-if="tableFalg">
      <table>
        <tbody>
          <tr v-for="(item,index) in selectList">
            <td v-for="(value,i) in item">{{value}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btns">
      <el-button type="success" @click="star">自动</el-button>
      <el-button type="primary" @click="stop">暂停</el-button>
      <el-button type="error" @click="reset">复位</el-button>
    </div>
    <!-- 最外面的字体 -->
    <template v-if="MoveFlag">
      <slogan :listData="data4" :colorList="colorList2" :DataArr="sixData" width="100vh" height="100vh" width1="100vh" height1="100vh" width3="105vh"></slogan>
    </template>
    <!-- 三角形 -->
    <triangle :borderColor="borderColor" @rota="setRota" :DataArr="fiveData" width="88vh" height="88vh" width1="5vh" height1="88vh" width3="5vh"></triangle>

    <paryt ref="party" :time="50" translateY="35vh" @hiddenMove="MoveFlag=false" :listData="data4" @rota="setRota" @editRow="fiveData=$event" :colorList="colorList2" :DataArr="fiveData" width="82vh" height="82vh" width1="8" height1="8" width3="5vh"></paryt>
    <paryt ref="party1" :time="100" translateY="30vh" @hiddenMove="MoveFlag=false" :listData="data3" @rota="setRota" @editRow="fourData=$event" :colorList="colorList2" :DataArr="fourData" width="65vh" height="65vh" width1="3" height1="3" width3="5vh"></paryt>
    <paryt ref="party2" :time="50" translateY="19vh" @hiddenMove="MoveFlag=false" :listData="data2" @rota="setRota" @editRow="threeData=$event" :colorList="colorList2" :DataArr="threeData" width="50vh" height="50vh" width1="8" height1="8" width3="4.5vh"></paryt>
    <paryt ref="party3" :time="100" translateY="10vh" @hiddenMove="MoveFlag=false" :listData="data1" @rota="setRota" @editRow="towData=$event" :colorList="colorList2" :DataArr="towData" width="30vh" height="30vh" width1="8" height1="8" width3="3vh"></paryt>
    <paryt ref="party4" fontColor="#000" :time="100" @hiddenMove="MoveFlag=false" :listData="data" @rota="setRota" @editRow="oneData=$event" translateY="5vh" :colorList="colorList" :DataArr="oneData" width="15vh" height="15vh" width1="4" height1="4" width3="4vh"></paryt>
    <div class="main">
      创建世界一流管道企业
    </div>
  </div>
</template>

<script >
import { debounce } from 'lodash'
import { colorList, colorList2, handlerData } from './index'
import slogan from '@/components/slogan.vue'
import triangle from '@/components/triangle.vue'
import paryt from '@/components/party.vue'
import { getlist } from '@/utils/network'
import { set } from 'vue'
export default {
  components: { paryt, triangle, slogan },
  data() {
    return {
      oneData: [],
      towData: [],
      threeData: [],
      fourData: [],
      fiveData: [],
      sixData: ['宣传思想文化', '女工工作', '工会工作', '党支部工作', '青年工作', '统战工作', '纪检监察'],
      data: {},
      data1: {},
      data2: {},
      data3: {},
      data4: {},
      data5: {},
      borderColor: '#000',
      mouse: false,
      colorList: colorList,
      colorList2: colorList2,
      tableFalg: false,
      flag: 0,
      MoveFlag: true,
      // 全局的角度 任何一个都可以进行更改
      rota: 0,
      isStar: false,
      isStop: false,
      selectList: [
        ['物理网', ''],
        ['载体网', ''],
        ['价值网', ''],
        ['角色网', ''],
        ['监督评价网', '']
      ]
    }
  },
  created() {
    this.getList()
  },
  watch: {
    rota: {
      handler(value) {
        let arr = this.oneData.filter((item) => {
          return Number(value) >= Number(item.start_du) && Number(value) < Number(item.end_du)
        })
        let arr1 = this.towData.filter((item) => {
          return Number(value) >= Number(item.start_du) && Number(value) < Number(item.end_du)
        })
        let arr2 = this.threeData.filter((item) => {
          return Number(value) >= Number(item.start_du) && Number(value) < Number(item.end_du)
        })
        let arr3 = this.fourData.filter((item) => {
          return Number(value) >= Number(item.start_du) && Number(value) < Number(item.end_du)
        })
        let arr4 = this.fiveData.filter((item) => {
          return Number(value) >= Number(item.start_du) && Number(value) < Number(item.end_du)
        })
        let arr5 = [...arr, ...arr1, ...arr2, ...arr3, ...arr4]
        // console.log(arr5, 'arr5')
        // console.log(arr[0], 'arr')
        // console.log(arr1[0], 'arr1')
        // console.log(arr2[0], 'arr2')
        // console.log(arr3[0], 'arr3')
        // console.log(arr4[0], 'arr4')
        // console.log(this.fourData, 'this.fourData')
        console.log(value, '筛选的角度')
        let that = this
        this.selectList.forEach((item, index) => {
          // item[1] = arr5[index].name
          that.$set(item, 1, arr5[index].name)
        })
        // console.log(this.selectList, 'selectListselectList')
      },

      deep: true
    }
  },
  methods: {
    star: debounce(function () {
      if (this.isStar) {
        return
      }
      this.isStar = true
      this.$refs.party.star()
      setTimeout(() => {
        this.$refs.party1.star()
      }, 1000)
      setTimeout(() => {
        this.$refs.party2.star()
      }, 2000)
      setTimeout(() => {
        this.$refs.party3.star()
      }, 3000)
      setTimeout(() => {
        this.$refs.party4.star()
      }, 3500)
    }),
    stop: debounce(function () {
      if (this.isStar) {
        this.$refs.party.stop()
        this.$refs.party1.stop()
        this.$refs.party2.stop()
        this.$refs.party3.stop()
        this.$refs.party4.stop()
        this.isStar = false
      }
    }),
    reset: debounce(function () {
      if (this.isStar) {
        return
      }
      this.oneData = []
      this.towData = []
      this.threeData = []
      this.fourData = []
      this.fiveData = []
      this.getList()
      this.$refs.party.reset()
      this.$refs.party1.reset()
      this.$refs.party2.reset()
      this.$refs.party3.reset()
      this.$refs.party4.reset()
    }),
    setRota(e) {
      this.tableFalg = true
      this.rota = e.rota
      // console.log(this.rota, "rota");
    },
    async getList() {
      const res = await getlist()
      this.list = res.data
      // console.log(this.list, 'list')
      res.data.forEach((item) => {
        if (item.level == 1) {
          this.oneData.push(item)
        } else if (item.level == 2) {
          this.towData.push(item)
        } else if (item.level == 3) {
          this.threeData.push(item)
        } else if (item.level == 4) {
          this.fourData.push(item)
        } else {
          this.fiveData.push(item)
        }
      })
      this.data = handlerData(this.oneData, colorList)
      this.data1 = handlerData(this.towData, colorList2)
      this.data2 = handlerData(this.threeData, colorList2)
      this.data3 = handlerData(this.fourData, colorList2)
      this.data4 = handlerData(this.fiveData, colorList2)
      this.data5 = handlerData(this.fiveData, colorList2)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
/* 禁用鼠标滑动选中文本的默认样式 */
* {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* 标准语法 */
}

/* 禁用鼠标滑动变蓝色的文本选择效果 */
::-webkit-selection {
  background: transparent;
}

::selection {
  background: transparent;
}

body {
  width: 100%;
  height: 100%;
}
// 最外面的容器

.clock {
  width: 100%;
  height: 100%;
  // background-color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  overflow: hidden;
  cursor: pointer;
  background: url('~@/assets/bj.png');
  background-size: 100% 100%;
  background: rgba(255, 255, 255, 0.5);
}

.main {
  width: 8vh;
  height: 8vh;
  position: absolute;
  display: flex;
  font-size: 1vh;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 1s;
  z-index: 999;
  color: #000;

  background-color: #fff;
}
.table {
  border: 1px solid #000;
  width: 40vh;
  position: absolute;
  left: 0;
  top: 5%;
  table {
    width: 100%;
    thead {
      width: 100%;
      td {
        padding: 10px;
        width: 50%;
      }
    }
    tbody {
      td {
        padding: 10px;
        width: 50%;
      }
    }
  }
}

td {
  border: 1px solid #000;
  border-collapse: collapse;
}
table {
  border-collapse: collapse; /* 确保边框合并 */
}

.btns {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  .el-button {
    width: 100%;
    margin: 0;
  }
}
</style>
