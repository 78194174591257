<template>
  <div class="box" ref="box" @mousedown="touchstart" @mousemove="touchmove" @mouseup="touchend" :style="boxStyle">
    <div class="main">
      <slot name="circle"></slot>
    </div>
    <!-- ,width:200px,height:500px -->
    <!-- :style="getitem(i-1,0)" -->
    <div class="item" style="transform: rotate(45deg);">
      <div class="area">
        <div class="show">
          111111111

        </div>
      </div>
    </div>
    <div class="item" style="transform: rotate(90deg);">
      <div class="area">
        <div class="show">
          222222222

        </div>
      </div>
    </div>
    <div class="item" style="transform: rotate(135deg);">
      <div class="area">
        <div class="show">
          3333333333

        </div>
      </div>
    </div>
    <div class="item" style="transform: rotate(180deg);">
      <div class="area">
        <div class="show">
          444444444

        </div>
      </div>
    </div>
    <div class="item" style="transform: rotate(225deg);">
      <div class="area">
        <div class="show">
          5555555555

        </div>
      </div>
    </div>
    <div class="item" style="transform: rotate(270deg);">
      <div class="area">
        <div class="show">
          666666666666

        </div>
      </div>
    </div>

    <div class="item" style="transform: rotate(315deg);">
      <div class="area">
        <div class="show">
          7777777777

        </div>
      </div>
    </div>

    <div class="item" style="transform: rotate(360deg);">
      <div class="area">
        <div class="show">
          8888888888888

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["width", "height", "width2", "height2"],
  name: "compass",
  data() {
    return {
      animation: null,
      degRota: 0,
      pageX: 0,
      page1X: 0,
      touchStartX: 0,
      touchStartY: 0,
      touchStartAngle: 0,
      disc: {},
      flag: false,
    };
  },
  computed: {
    boxStyle() {
      return {
        width: this.width,
        height: this.height,
        transform: `rotate(${this.degRota}deg)`,
      };
    },
  },
  methods: {
    getitem(index, off) {
      // return `transform: rotateZ(${index * 45 + off}deg);`
      let num = index * 45 + off;

      return {
        transform: `rotateZ(${num}deg)`,
        width: this.width2,
        height: this.height2,
      };
    },
    touchstart(e) {
      this.disc = this.$refs.box;
      this.flag = true;
      this.touchStartX = e.clientX;
      this.touchStartY = e.clientY;
      // // Calculate the initial angle
      this.touchStartAngle =
        Math.atan2(
          this.touchStartY - this.disc.offsetTop,
          this.touchStartX - this.disc.offsetLeft
        ) *
        (180 / Math.PI);
    },
    touchmove(e) {
      if (this.flag) {
        e.preventDefault();
        const touchX = e.clientX;
        const touchY = e.clientY;
        const touchAngle =
          Math.atan2(
            touchY - this.disc.offsetTop,
            touchX - this.disc.offsetLeft
          ) *
          (180 / Math.PI);
        const angleDifference = touchAngle - this.touchStartAngle;
        this.degRota = angleDifference;
        console.log(this.degRota, " this.degRota ");
      }
    },
    touchend(e) {
      console.log("songkai");
      this.flag = false;

      // Reset the initial touch coordinates and angle
      this.touchStartX = null;
      this.touchStartY = null;
      this.touchStartAngle = null;
      this.disc = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  // background-color: pink;
  background: radial-gradient(#fff 0% #fff 25%);
  // background: conic-gradient(
  //   #811c1d 10.5%,
  //   #fff 0.5%,
  //   #af2023 10.5%,
  //   #fff 0.5%,
  //   #e62129 10.5%,
  //   #fff 0.5%,
  //   #ded572 10.5%,
  //   #fff 0.5%,
  //   #c4a13f 10.5%,
  //   #fff 0.5%,
  //   #fffcdb 10.5%,
  //   #fff 0.5%,
  //   #757475 10.5%
  // );
  // background: conic-gradient(#811c1d 100deg, #fff 60deg, orange 45deg);
  background-image: conic-gradient(
    from 25deg,
    #811c1d 0deg,
    #811c1d 43deg,
    #fff 43deg,
    #fff 45deg,
    #af2023 45deg,
    #af2023 87deg,
    #fff 87deg,
    #fff 90deg,
    #e62129 90deg,
    #e62129 132deg,
    #fff 132deg,
    #fff 135deg,
    #ded572 135deg,
    #ded572 177deg,
    #fff 177deg,
    #fff 180deg,
    #c4a13f 180deg,
    #c4a13f 222deg,
    #fff 222deg,
    #fff 225deg,
    #fffcdb 225deg,
    #fffcdb 267deg,
    #fff 267deg,
    #fff 270deg,
    #757475 270deg,
    #757475 315deg
  );
  border-radius: 50%;

  border-radius: 50%;
  // transform: translate(-50%, -50%) scale(1.7);
  // 把子元素 item 全部放在 中间
  display: flex;
  justify-content: center;
  align-items: center;
  // 隐藏
  overflow: hidden;
  // 每一个单独的 一共 8 个
  .item {
    position: absolute;
    // top: -200px;
    width: 500px;
    height: 500px;
    // background-color: red;
    // flex 布局   因为旋转的原因
    display: flex;
    // align-items: center; // 副轴居中
    justify-content: center; // 主轴居中
    color: #fff;

    border-bottom: 1px solid #fff;
    // .area {
    //   position: absolute;
    //   z-index: 99;
    //   padding: 5%;
    //   box-sizing: border-box;
    //   padding-top: 10%;
    //   width: 60%;
    //   height: 30%;
    //   background-color: rgba(249, 240, 104, 0.5);
    //   transform: perspective(2em) rotateX(-20.5deg);
    //   overflow: hidden;
    //   // border: 2px inset #ffffff;
    //   .show {
    //     // 字体的旋转
    //     transform: perspective(2em) rotateX(8.5deg);
    //   }
    // }
    .area {
      position: absolute;
      z-index: 99;
      padding: 10%;
      box-sizing: border-box;
      // padding-top: 10%;
      width: 5%;
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20%;
      // background-color: rgba(249, 240, 104, 0.5);
      // rotateX  是水平轴转 而不是单纯的转 是向后转
      // transform: perspective(2em) rotateX(-20.6deg);
      .show {
        // 字体的旋转
        word-wrap: break-word;
        width: 50px;
        // transform: perspective(2em) rotateX(8.5deg);
      }
    }
  }
}

// 中心圆圈
.main {
  position: absolute;
  width: 140px;
  height: 140px;
  // border: 10px inset brown;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: #fff;
}
</style>