var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"box",staticClass:"box",style:(_vm.boxStyle),on:{"mousedown":_vm.touchstart,"mousemove":_vm.touchmove,"mouseup":_vm.touchend}},[_c('div',{staticClass:"main"},[_vm._t("circle")],2),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"transform":"rotate(45deg)"}},[_c('div',{staticClass:"area"},[_c('div',{staticClass:"show"},[_vm._v(" 111111111 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"transform":"rotate(90deg)"}},[_c('div',{staticClass:"area"},[_c('div',{staticClass:"show"},[_vm._v(" 222222222 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"transform":"rotate(135deg)"}},[_c('div',{staticClass:"area"},[_c('div',{staticClass:"show"},[_vm._v(" 3333333333 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"transform":"rotate(180deg)"}},[_c('div',{staticClass:"area"},[_c('div',{staticClass:"show"},[_vm._v(" 444444444 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"transform":"rotate(225deg)"}},[_c('div',{staticClass:"area"},[_c('div',{staticClass:"show"},[_vm._v(" 5555555555 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"transform":"rotate(270deg)"}},[_c('div',{staticClass:"area"},[_c('div',{staticClass:"show"},[_vm._v(" 666666666666 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"transform":"rotate(315deg)"}},[_c('div',{staticClass:"area"},[_c('div',{staticClass:"show"},[_vm._v(" 7777777777 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"transform":"rotate(360deg)"}},[_c('div',{staticClass:"area"},[_c('div',{staticClass:"show"},[_vm._v(" 8888888888888 ")])])])
}]

export { render, staticRenderFns }