<template>

  <div class="plan_box">
    <div class="back">
      <el-link type="primary" @click="back()">返回</el-link>
    </div>
    <el-card class="box-card" v-for="(item,index) in tableData" :key="index">
      <div class="title">
        {{item.title}}
      </div>
      <table>

        <thead>
          <tr>
            <td class="head" v-for="(value,index1) in item.content[0] " :key="index1"> {{value}}</td>
          </tr>
        </thead>
        <tbody>
          <tr v-if="i>0" v-for="(value,i) in item.content">
            <td @dblclick="handleDoubleClick(item.content,i,i1,value1,index)" :style="{'width':100 / item.length+'%' }" v-for="(value1,i1) in value" :key="i1">{{value1}}</td>

          </tr>

        </tbody>
      </table>
    </el-card>
    <el-dialog title="选择分类" :visible.sync="dialogVisible" width="50%" :close-on-press-escape="false" :show-close="false">
      <div class="select">
        <div :class="{'active':current==index}" @click="select(item,index)" v-for="(item,index) in list" class="select_tiem">{{item.name}}</div>
      </div>
      <span slot="footer" class="dialog-footer">

        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="danger" @click="confrim">确 定</el-button>

      </span>
    </el-dialog>
  </div>

</template>

<script >
import { getDetails, getDetails2 } from '@/utils/network'

export default {
  data() {
    return {
      current: 0,
      dialogVisible: false,
      tableData: [],
      headerData: [],
      total: 0,
      queryParams: {
        page: 1,
        limit: 10
      },
      list: [],
      type_id: 0,
      propsList: []
    }
  },
  async created() {
    if (this.$route.query.id == 76 || this.$route.query.id == 14 || this.$route.query.id == 36 || this.$route.query.id == 38) {
      let data = {
        type_id: this.$route.query.id
      }
      const res = await getDetails2(data)
      console.log(res)
      this.list = res.data
      this.dialogVisible = true
      return
    }

    this.getList()
  },
  methods: {
    select(item, index) {
      this.current = index
    },
    back() {
      this.$router.back()
    },
    async confrim() {
      let data = {
        type_id: this.list[this.current].id
      }
      const res = await getDetails(data)
      this.tableData = res.data
      this.dialogVisible = false
    },
    async getList() {
      let data = {
        type_id: this.$route.query.id
      }
      const res = await getDetails(data)
      this.tableData = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
.plan_box {
  margin: 40px auto;
  padding-top: 50px;
  width: 100%;

  table {
    width: 100%;
    height: 100%;
  }
  .title {
    margin-bottom: 20px;
  }
  thead {
    width: 100%;
    // display: flex;
    // justify-content: space-between;
    .head {
      padding: 20px;
      text-align: center;
    }
  }
  tbody {
    tr {
      width: 100%;

      td {
        padding: 10px;
      }
    }
  }
}
.back {
  position: absolute;
  top: 5vh;
  left: 5vh;
}
td {
  border: 1px solid #000;
}
table {
  border-collapse: collapse; /* 确保边框合并 */
}
.box-card {
  margin-bottom: 40px;
}
.select {
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  height: 11vh;
  display: flex;
  justify-content: space-between;
  .select_tiem {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5vh;
    width: 48%;
    height: 100%;
    border: 1px solid #000;
    border-radius: 15px;
  }
  .active {
    background: red;
    color: #fff;
    border: 1px solid red;
  }
}
</style>

