<template>
  <div class="clock">
    <div class="loginForm">
      <div class="lopgin_title">“五网三格一赋能”党建网格化管理平台</div>
      <div class="form">
        <el-form :model="form" :rules="rules" ref="ruleForm" class="demo-editForm" label-width="100px" label-position="left">

          <el-form-item label="用户名" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="password">
            <el-input @keyup.enter.native="login" v-model="form.password" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="password_two" v-if="!isLogin">
            <el-input @keyup.enter.native="login" v-model="form.password_two" placeholder="请输入"></el-input>
          </el-form-item>

        </el-form>

        <div class="login_select">
          <span v-if="isLogin" @click="toggle()">用户注册</span>
          <span v-else @click="toggle()">用户登录</span>

        </div>
        <div class=" submit" @click="login">立即{{isLogin?'登录':'注册'}}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Userlogin, UserReg } from "@/utils/network.js";
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      console.log(value);
      if (!value) {
        callback(new Error("请输入"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      isLogin: true,
      form: {},
      rules: {
        phone: [{ required: true, message: "请输入", trigger: "blur" }],
        password: [{ required: true, message: "请输入", trigger: "blur" }],
        password_two: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    toggle() {
      this.isLogin = !this.isLogin;
    },
    login() {
      console.log(111);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            ...this.form,
          };
          if (this.isLogin) {
            Userlogin(data).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: "登录成功",
                  type: "success",
                });
                this.$router.push("/index");
              }
            });
          } else {
            UserReg(data).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: "注册成功",
                  type: "success",
                });
                this.isLogin = true;
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    async getUserinfo(member_id) {
      const res = await getUser({
        member_id,
      });
      this.$message({
        message: "登录成功",
        type: "success",
      });

      localStorage.setItem("userInfo", JSON.stringify(res.data));
      this.$router.replace("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.clock {
  width: 100%;
  height: 100%;
  // background-color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  overflow: hidden;
  cursor: pointer;
  background: url("~@/assets/bj.png");
  background-size: 100% 100%;
  background: rgba(255, 255, 255, 0.5);
}
.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);

  z-index: 99;
  .lopgin_title {
    font-size: 5vh;
    margin-bottom: 2vh;
    color: #af2024;
    font-weight: 700;
  }
  // background: #ffffff;
  .form {
    width: 57vh;
    border: 1vh solid #f4ba99;
    background: #ffe8ce;
    padding: 5vh 3vh;
  }
  .el-form-item {
    // display: flex;
    // // align-items: center;
    // height: 5vh;
    // .el-input {
    //   height: 5vh;
    //   ::v-deep .el-input__inner {
    //     height: 100% !important;
    //   }
    // }
  }
  .login_select {
    display: flex;
    justify-content: flex-end;
    font-size: 1.5vh;
    margin-left: 1vh;
  }
  .submit {
    margin: 3vh auto;
    margin-bottom: 0;
    width: 80%;
    height: 5vh;
    background: #b03f37;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 2.5vh;
    color: #fffefe;
  }
}
</style>
