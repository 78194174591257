<template>

  <div class="box" ref="box" :style="getBack()" @mousedown.self="touchstart" @mousemove="touchmove" @mouseup="touchend" @mouseleave="touchend">
    <!-- <div class="item" @click ="listItemClicked" v-for="(item,index) in DataArr " :style="getItemStyle(index)">

      <div class="area" :style="{'width':width3}">
        <div @click ="jump(item,index)">
          {{item.name}}
        </div>

      </div>
    </div> -->
    <div class="circle " ref="circle" @click="jump(item,index)" v-for="(item,index) in DataArr " :style="getItemStyle(index)">
      <div class="text" :style="{'width':width3}">
        {{item.name}}
        <!-- {{index}} -->
      </div>
      <!-- <div class="area" :style="{'width':width3}">
        <div @click ="jump(item,index)">
          {{item.name}}
        </div>

      </div> -->
    </div>

  </div>

</template>

<script >
import _ from 'lodash'
import { getAngle2, throttle } from '@/utils/index'
import { getDetails2 } from '@/utils/network'

export default {
  name: 'paryt',
  props: ['time', 'DataArr', 'width', 'height', 'width1', 'height1', 'colorList', 'width3', 'listData', 'translateY'],
  data() {
    return {
      touchStartX: 0,
      touchStartY: 0,
      touchStartAngle: 0,
      flag: false,
      centerPointerLeft: 0,
      centerPointerTop: 0,
      degRota: 0,
      radius: 0,
      baseColor: '#fff',
      // list: [],
      // halfNum: 0,
      starRota: 0,
      endRota: 0,
      refBox: {},
      rota: 0,
      isMouseDown: false,
      difference: 0,
      flag1: false,
      ParydegRota: 0,
      timer: null,
      list: []
    }
  },

  watch: {
    DataArr: {
      handler(value) {},
      immediate: true,
      deep: true
    }
    // handlerData
  },
  created() {
    // this.star()
    // setTimeout(() => {
    //   this ()
    // }, 5000)
  },
  methods: {
    listItemClicked(event) {
      console.log('listItemClicked')
      // console.log(event)
      const clickedItem = event.target.textContent
      console.log(`Clicked on: ${clickedItem}`)
    },
    star() {
      this.starRota = this.degRota
      console.log(this.degRota, 'star')
      this.timer = setInterval(() => {
        if (this.degRota >= 359) {
          return
        }
        this.degRota++
      }, this.time)
    },
    stop() {
      clearInterval(this.timer)
      this.timer = null
      // 计算角度赋值
      this.EditData(this.degRota - this.starRota)
    },
    reset() {
      this.degRota = 0
      this.ParydegRota = 0
      this.flag = false
      this.touchStartX = null
      this.touchStartY = null
      this.touchStartAngle = null
      this.disc = null
    },
    // from ${this.listData.halfNum}deg,
    getBack() {
      let str = `conic-gradient(`
      this.listData.list.forEach((item, index) => {
        if (index == this.listData.list.length - 1) {
          str += `${item}`
        } else {
          str += `${item},`
        }
      })
      str += `)`
      return {
        backgroundImage: str,
        width: this.width,
        height: this.height,
        transform: `rotate(${this.degRota}deg)`
      }
    },
    // item    circle 样式
    getItemStyle(index) {
      // 这个代码 加一半是为了居中
      let num = 360 / this.DataArr.length / 2
      //  + num
      return {
        transform: `rotate(${index * (360 / this.DataArr.length) + 180 + num}deg)     translateY(${this.translateY})`,
        width: `${this.width1}vh`,
        height: `${this.height1}vh`,
        marginTop: `-${Number(this.height1) / 2}vh`,
        marginLeft: `-${Number(this.width1) / 2}vh`
      }
    },
    // 点击进去  和点击一段时间滑动
    async jump(item, index) {
      if (!this.isMouseDown) {
        this.$router.push({ path: '/details', query: { id: item.id } })
      }
    },
    touchstart(e) {
      this.refBox = this.$refs.box
      this.centerPointerLeft = this.refBox.offsetLeft + this.refBox.offsetWidth / 2
      this.centerPointerTop = this.refBox.offsetTop + this.refBox.offsetHeight / 2
      this.radius = this.refBox.offsetHeight / 2
      this.flag = true

      this.touchStartX = e.clientX //startX
      this.touchStartY = e.clientY //startY

      let rota = this.updateValue(e.offsetX, e.offsetY)
      // console.log(rota)
      // this.difference = 360 - rota
      this.difference = rota
    },
    touchmove: _.throttle(function (e) {
      if (this.flag) {
        this.$emit('hiddenMove', {})
        setTimeout(() => {
          this.isMouseDown = true
        }, 1000)
        const currentX = e.clientX
        const currentY = e.clientY
        let degRota = getAngle2(this.touchStartX, this.touchStartY, currentX, currentY, this.centerPointerLeft, this.centerPointerTop)
        // 限制只能转一圈 360度
        // difference 点击的位置度数 相当于转的度数
        this.degRota = degRota + this.endRota
        this.ParydegRota = degRota
      }
    }, 50),
    touchend(e) {
      if (this.flag) {
        setTimeout(() => {
          this.isMouseDown = false
        }, 200)

        this.EditData(this.ParydegRota)
        // this.$emit('rota', { rota: this.rota })
        this.endRota = this.degRota
        // console.log(this.endRota, ' this.endRota ')
        this.flag = false
        this.touchStartX = null
        this.touchStartY = null
        this.touchStartAngle = null
        this.disc = null
      }
    },
    updateValue(offsetX, offsetY) {
      let min = 0,
        max = 360
      const centerX = this.refBox.offsetWidth / 2
      const centerY = this.refBox.offsetHeight / 2
      const deltaX = offsetX - centerX
      const deltaY = offsetY - centerY
      let angle = Math.atan2(deltaY, deltaX) + Math.PI / 2
      // 转换为角度 0-360
      let newValue = ((angle * 180) / Math.PI + 360) % 360
      // 将360分段
      newValue = Math.round(newValue / 3.6) * 3.6
      // 根据百分比计算滑块的值
      newValue = (newValue / 360) * (max - min) + min
      if (newValue <= min) {
        newValue = max
      } else if (newValue > max) {
        newValue = max
      }
      this.rota = newValue.toFixed(0)
      return this.rota
    },
    EditData(ParydegRota) {
      console.log(ParydegRota, '旋转结束的度数')
      this.DataArr.forEach((item) => {
        item.start_du = Number(ParydegRota) + Number(item.start_du)
        item.end_du = Number(ParydegRota) + Number(item.end_du)
        if (item.start_du > 360) {
          item.start_du = item.start_du - 360
        }
        if (item.end_du > 360) {
          item.end_du = item.end_du - 360
        }
        if (item.start_du < 0) {
          item.start_du = item.start_du + 360
        }
        if (item.end_du < 0) {
          item.end_du = item.end_du + 360
        }
      })
      console.log(this.DataArr, 'this.DataArr')
      this.$emit('editRow', this.DataArr)
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
  border-radius: 50%;
  transition: 1s;
  border: 0.5vh solid #fff;

  // overflow: hidden;
}
.item {
  position: absolute;
  display: flex;
  justify-content: center; // 主轴居中
  color: #fff;
  // border-radius: 50%;

  overflow: hidden;
  // background-color: pink;
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  padding-top: 2vh;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  color: #000;
  z-index: 999;
  box-sizing: border-box;
  word-wrap: break-word;
  font-size: 1.1vh;
  // background-color: aqua;
  .text {
    transform: rotate(180deg);
  }
}
</style>
