import HttpRequest from '@/utils/request'

export  function getlist(data){
	return HttpRequest({url:'/member/word_list',data})
}


export  function getDetails(data){
	return HttpRequest({url:'/member/get_content',data})
}


export  function getDetails2(data){
	return HttpRequest({url:'/member/chongfu',data})
}

export  function UserReg(data){
	return HttpRequest({url:'/member/reg',data})
}




export  function Userlogin(data){
	return HttpRequest({url:'/member/login',data})
}

