export const getAngle2= (x1, y1, x2, y2, cx, cy)=> {
  //2个点之间的角度获取
  let c1 = (Math.atan2(y1 - cy, x1 - cx) * 180) / Math.PI;
  let c2 = (Math.atan2(y2 - cy, x2 - cx) * 180) / Math.PI;
  let angle;
  if (c1 <= -90 ) {
    c1 = 360 + c1
  }
  if (c2 <= -90 ) {
    c2 = 360 + c2
  }
  //夹角获取
  angle = Math.floor(c2 - c1);
  //  angle = (c2 - c1 + 360) % 360;
  // angle = angle < 0 ? angle + 360 : angle;
  // console.log(angle,'angle');

  return angle;
} 


// 只要我拖动鼠标 到 0度的时候 就出现了问题
// 观察 鼠标 滑动到 0 度的时候 360  鼠标滑动的角度 +  点击的角度   应该都是等于360
// 并且当我从 270 度 滑动 80 度 返回的就是 -270度
// 也是炒过了 360
// this.difference = 360 - rota // 计算的角度差值（还差多少度就重置了） 还可以滑动多少度
// rota    点击的角度（可以理解为以及滑动的角度）
//  degRota 当  degRota  超过了 this.difference 就到达了临界点  就要开始重置
// 到了临界点之后 转动第二圈要累加 之前的
// 到了临界点 比如我转了90  返回的是 -270
// 如果返回了- 270  证明我从正面转了90
 

 export const throttle = (fn, interval)=> {
  // 1.记录上一次的开始时间
  let lastTime = 0
 
  // 2.事件触发时, 真正执行的函数
  const _throttle = function () {
 
    // 2.1.获取当前事件触发时的时间
    const nowTime = new Date().getTime()
 
    // 2.2.使用当前触发的时间和之前的时间间隔以及上一次开始的时间, 计算出还剩余多长时间需要去触发函数
    const remainTime = interval - (nowTime - lastTime)
    //第一次会执行，原因是nowTime刚开始是一个很大的数字，结果为负数
    //若最后一次没能满足条件，不会执行
    if (remainTime <= 0) {
      // 2.3.真正触发函数
      fn()
      // 2.4.保留上次触发的时间
      lastTime = nowTime
    }
  }
 
  return _throttle
}