import Vue from 'vue'
import VueRouter from 'vue-router'

import index from '../views/index.vue'
import details from '@/views/details.vue'
import login from '@/views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
    // name: 'home',
    // component: HomeView
  },
  {

    path: '/index',
    name: 'index',
    component: index
  },
  {
    path: '/details',
    name: 'details',
    component: details
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },



]

const router = new VueRouter({
  routes,
  mode: 'hash'

})

export default router
